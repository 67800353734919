import { ActionReducer } from '@ngrx/store';
import { CompanyState } from '../states/company.state';
import { CompanyActions } from '../actions/company.action';

export function companyReducer(
    state: CompanyState.IState = CompanyState.initialState,
    action: CompanyActions.Actions,
): CompanyState.IState {
    const changeState = (customPayload?: any) => {
        return Object.assign({}, state, customPayload || action.payload);
    };
    switch (action.type) {
        case CompanyActions.ActionTypes.CHANGED:
            return changeState();
        default:
            return state;
    }
};
