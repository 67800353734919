import { APP_INITIALIZER, NgModule } from '@angular/core';
import {
  Routes,
  RouterModule,

  Router,
  UrlSegment,
  Route,
  UrlSegmentGroup,
  UrlMatchResult
} from '@angular/router';
import {
  companyValidSubscriptionGuard,
  JobRedirectGuard,
  applicantAuthGuard
} from './app.guards';
import { LayoutComponent } from './layout/layout.component';
import { LoaderPageComponent } from './loader-page/loader-page.component';
import { CustomDomainService } from './company/services/custom-domain/custom-domain.service';
import { environment } from '../environments/environment';
import { isAuthenticated } from './auth/guards/auth.guard';
import { ImpersonateGuard } from './auth-apply/services';



export function companyUrlMatcher(
  segments: UrlSegment[],
  group: UrlSegmentGroup,
  route: Route
): UrlMatchResult | null {
   // Expect at least one segment for the company name
  if (segments.length >= 1) {
    const companyNameSegment = segments[0];
    companyNameSegment.path = companyNameSegment.path.split(/[&]/)[0]

    // Only consume the first segment
    return {
      consumed: [companyNameSegment],
      posParams: {
        companyName: companyNameSegment // Capture companyName
      }
    };
  }

  return null; // Return null if there's no match
}


const routes: Routes = [
  {
    path: 'auth/impersonate/:token',
    canActivate: [ImpersonateGuard],
    children: [],
  },
  {
    path: '',
    loadComponent: () =>
            import('./website/website.component')
                .then(m => m.WebsiteComponent),
    canActivate: [isAuthenticated]
  },
  {
    path: 'book-a-demo',
    loadComponent: () =>
            import('./book-a-demo/book-a-demo.component')
                .then(m => m.BookADemoComponent),
  },
  {
    path: 'book-a-demo-huma',
    loadComponent: () =>
      import('./book-a-demo/book-a-demo.component')
        .then(m => m.BookADemoComponent),
    data: { isMarketingPage: true }
  },
  {
    path: 'price',
    loadComponent: () =>
            import('./price/price.component')
                .then(m => m.PriceComponent),
  },
  {
    path: 'contact',
    loadComponent: () =>
            import('./contact/contact.component')
                .then(m => m.ContactComponent),
  },
  {
    path: 'video-series',
    loadComponent: () =>
            import('./video-series/video-series.component')
                .then(m => m.VideoSeriesComponent),
  },
  {
    path: 'privacy',
    loadComponent: () =>
            import('./privacy/privacy.component')
                .then(m => m.PrivacyComponent),
  },
  {
    path: 'cookie-policy',
    loadComponent: () =>
      import('./website/cookies-policy/cookies-policy.component')
        .then(m => m.CookiesPolicyComponent),
  },
  {
    path: 'about-nuu',
    loadComponent: () =>
      import('./website/marketing/marketing.component')
        .then(m => m.MarketingComponent),
  },
  {
    path: 'about-nuu/thank-you',
    loadComponent: () =>
      import('./website/thank-you/thank-you.component')
        .then(m => m.ThankYouComponent),
  },
  {
    path: '',
    loadChildren: () => import('./promo-pages/promo-pages.module').then(m => m.PromoPagesModule)
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
      },
      {
        path: 'bruker',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule),
        canActivate: [applicantAuthGuard],
      },
      {
        path: 'kontakt',
        loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule),
        canActivate: [applicantAuthGuard],
      },
      {
        path: 'stilling/:jobId',
        component: LoaderPageComponent,
        canActivate: [JobRedirectGuard]
      },
      {
        path: 'sok-stilling/:jobId',
        component: LoaderPageComponent,
        canActivate: [JobRedirectGuard, companyValidSubscriptionGuard],
        data: { isPublic: true }
      },
      {
        path: 'authorize',
        children: [
          {
            path: 'goodbye',
            loadComponent: () =>
              import('./shared/components/deleted-account/deleted-account.component')
                .then(m => m.DeletedAccountComponent),
          },
          {
            path: 'invalid',
            loadComponent: () =>
              import('./company/components/invalid-subscription/invalid-subscription.component').then(m => m.InvalidSubscriptionComponent)
          }
        ],
      },
      {
        path: '',
        loadChildren: () => import('./applicants/applicants.module').then(m => m.ApplicantsModule),
      },
      {
        path: 'auth',
        loadChildren: () => import('./auth-apply/auth-apply.module').then(m => m.AuthApplyModule),
      },
      {
        path: '404',
        loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule),
      },
      {
        path: 'company-select',
        loadChildren: () => import('./company-select/company-select.module').then(m => m.CompanySelectModule),
      },
      {
        matcher: companyUrlMatcher,
        loadChildren: () => import('./company-profile/company-profile.module').then(m => m.CompanyProfileModule),
      },
      {
        matcher: companyUrlMatcher,
        loadChildren: () => import('./company/company.module').then(m => m.CompanyModule),
      },
      {
        path: 'integrasjon',
        loadChildren: () =>
          import('./landings/integrations.routes').then(m => m.routes)
      },
      {
        path: 'integrasjoner',
        loadChildren: () =>
          import('./landings/integrations-marketing.routes').then(m => m.routes)
      },
      {
        path: 'bransje',
        loadChildren: () =>
          import('./landings/industries.routes').then(m => m.routes)
      },
      {
        path: '**',
        redirectTo: '404'
      }
    ],
  }
];

const routes_cd: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./company-profile/company-profile.module').then(m => m.CompanyProfileModule),
      },
      {
        path: '404',
        loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule),
      },
      {
        path: '**',
        redirectTo: '404'
      }
    ]
  },
];

let providers = [];
let appRoutes = [];

if (environment.appUrl !== 'http://localhost:3000') {
  providers = [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [CustomDomainService, Router]
    }
  ];

} else {
  // Commands to run locally:
  // ng serve --host localhost --port 3000 or just ng serve --port 3000 - for localhost
  // ng serve --host your.custom.domain --port 3000                     - for custom domain

  // uncomment this one if you run localhost
  // appRoutes = routes;
  // uncomment following code if you run custom domain (works locally as well, but breaks login redirections)
  providers = [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [CustomDomainService, Router]
    }
  ];
}

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {
    // enableTracing: true  // <-- debugging purposes only
  })],
  exports: [RouterModule],
  providers
})
export class AppRoutingModule {}

export function appInitializer(customDomainService: CustomDomainService, router: Router): () => Promise<void> {
  return async () => {
    const selectedRoutes = customDomainService.isCustomDomain() ? routes_cd : routes;

    router.resetConfig(selectedRoutes);
    // await router.navigateByUrl(router.url);
  };
}
