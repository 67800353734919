import { Action } from '@ngrx/store';
import { type } from '../../helpers/type';

export namespace DeviceActions {
    const CATEGORY: string = 'Device';

    export interface IActions {
        NAVIGATOR_DEVICE: string;
        DEVICE_RES: string;
    }

    export const ActionTypes: IActions = {
        NAVIGATOR_DEVICE: type(`${CATEGORY} Navigator Device Action`),
        DEVICE_RES: type(`${CATEGORY} Device Res`),
    };

    export class NavigatorDeviceAction implements Action {
        type = ActionTypes.NAVIGATOR_DEVICE;

        constructor(public payload?: string) { }
    }

    export class DeviceResolutionAction implements Action {
        type = ActionTypes.DEVICE_RES;

        constructor(public payload?: number[]) { }
    }

    export type Actions = NavigatorDeviceAction | DeviceResolutionAction;
}
