import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { Injectable } from '@angular/core';


/**
 * Custom route reuse strategy
 * When user navigates to the same component - component will be rebuilt instead of reusing the same instance
 * To use it - add data: { shouldNotReuseRoute: true } to the route
 */
@Injectable({
  providedIn: 'root',
})
export class CustomRouteReuseStrategy extends RouteReuseStrategy {
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.data?.shouldNotReuseRoute ? false : future.routeConfig === curr.routeConfig;
  }
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
  }
}
