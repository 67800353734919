import { environment } from '../../../../environments/environment';

export type TLangCode = 'no' | 'en' | 'sv' | 'da' | 'fi';
export type TLangName = 'Norsk' | 'English' | 'Svenska' | 'Dansk' | 'Finnish';

export interface ILang {
    code: TLangCode;
    name: TLangName;
}

const languages = <ILang[]>environment.languages;

export namespace LocaleState {
    const langObj: {
        [key: string]: TLangCode
    } = {};
    languages.forEach(_lang => {
        langObj[_lang.code] = _lang.code;
    });
    export const Locale = Object.freeze(langObj);

    export type Locale = keyof typeof Locale;
}
