import { Injectable } from '@angular/core';

@Injectable()
export class EmployeeService {

    sortEmployees(employees, criterion= 'first_name') {
        try {
            return employees.sort((l, r) => {
                return l[criterion].localeCompare(r[criterion]);
            });
        } catch (e) {
            return employees;
        }
    }
}
