import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { exhaustMap, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CalendarConnectData, CalendarServiceModel, User } from '../../../../shared/models/user/user.model';
import { LocaleActions } from '../../../actions/locale/locale.action';
import { IAppState } from '../../../ngrx';
import { CompanyActions } from '../../company/actions/company.action';
import { UserActions } from '../actions/user.action';
import { UserService } from '../services/user.service';
import { CalendarService } from '../../../../shared/services/google/calendar.service';
import { AuthActions } from '../../../../auth';
import { AuthService } from '../../../../auth/services/auth.service';
import { ModalActions } from '../../../actions/modal/modal.action';
import { ToastComponent } from '../../../../ui/toast/toast.component';
import { ErrorHandlingService } from '../../../services/error-handling/error-handling.service';



@Injectable()
export class UserEffects {
    fetchCurrentUser$: Observable<Action> = createEffect(() => this._actions$
      .pipe(
        ofType(UserActions.ActionTypes.FETCH),
        withLatestFrom(this._store),
        exhaustMap(([action, state]: [UserActions.FetchAction, IAppState]) => {
          if (state.user.current && !action.payload.force) {
            return of(
              new UserActions.ChangedAction({
                current: Object.assign({}, state.user.current),
                errors: [],
              }),
            );
          }
          return this._userService
            .getCurrentUser().pipe(
              mergeMap(user => {
                return [
                  new LocaleActions.SetAction(user.user_profile.language.toLowerCase()),
                  new UserActions.ChangedAction({
                    current: user,
                    errors: [],
                  }),
                ];
              }),
              catchError(error => of(new UserActions.ApiErrorAction({
                name: 'fetchCurrentUser',
                error
              })))
            );
        })));

    load$: Observable<Action> = createEffect(() => this._actions$
      .pipe(
        ofType(UserActions.ActionTypes.LOAD),
        tap(() => new UserActions.ChangedAction({ errors: [] })),
        switchMap((action: UserActions.LoadAction) => {
          const token = action.payload;

          this.authService.setTokenToStorage(token);

          return this._userService
            .getCurrentUser().pipe(
              map(user => {
                return AuthActions.loginSuccess({
                  access: token?.access,
                  refresh: token?.refresh,
                  user: user
                });
              }),
              catchError(error => {
                return of(new UserActions.ApiErrorAction({
                  name: 'load',
                  error
                }));
              }));
        })));

    patch$ = createEffect(() => this._actions$
      .pipe(
        ofType(UserActions.ActionTypes.PATCH),
        withLatestFrom(this._store),
        filter(([action, state]: [UserActions.PatchAction, IAppState]) => !!state.user.current),
        switchMap(([action, state]: [UserActions.PatchAction, IAppState]) => {
          const req = Object.assign({
            id: state.user.current.id,
          }, action.payload);
          return this._userService
            .patchUser(req).pipe(
              map(user => {
                return new UserActions.ChangedAction({ current: user });
              }),
              catchError(error => of(new UserActions.ApiErrorAction({
                name: 'patch',
                error
              }))));
        })));

    patch_settings$ = createEffect(() => this._actions$
      .pipe(
        ofType(UserActions.ActionTypes.PATCH_SETTINGS),
        withLatestFrom(this._store),
        filter(([action, state]: [UserActions.PatchSettingsAction, IAppState]) => !!state.user.current),
        switchMap(([action, state]: [UserActions.PatchSettingsAction, IAppState]) => {
          return this._userService
            .patchSettings(state.user.current.id, action.payload).pipe(
              map(settings => {
                return new UserActions.ChangedAction({ current: Object.assign({}, state.user.current, {
                    user_settings: settings
                  }) });
              }),
              tap(() => {
                // this._store.dispatch(new NotificationActions.FetchAction());
              }),
              catchError(error => of(new UserActions.ApiErrorAction({
                name: 'patchSettings',
                error
              }))));
        })));

    changeLanguage$ = createEffect(() => this._actions$
      .pipe(
        ofType(UserActions.ActionTypes.CHANGE_LANGUAGE),
        withLatestFrom(this._store),
        filter(([action, state]: [UserActions.ChangeLanguageAction, IAppState]) => !!state.user.current),
        filter(([action, state]: [UserActions.ChangeLanguageAction, IAppState]) => action.payload !== state.user.current.user_profile.language),
        map(([action, state]: [UserActions.ChangeLanguageAction, IAppState]) => {
          const req = {
            user_profile: {
              language: action.payload,
            }
          };
          return new UserActions.PatchAction(req);
        })));

    create$: Observable<any> = createEffect(() => this._actions$
      .pipe(
        ofType(UserActions.ActionTypes.CREATE),
        tap(() => new UserActions.ChangedAction({ errors: [] })),
        switchMap((action: UserActions.CreateAction) => {
          return this._userService
            .createUser(action.payload.user).pipe(
              map(() => {
                const minAttemptsWarning = 5; // Number of attempts to show warning message
                const maxAttempts = 7; // Number of attempts to block the form
                const attemptsName = 'createApplicantNumber';

                this.errorService.handleAttemptsNumber({
                  endpointName: attemptsName,
                  minAttemptsWarning,
                  maxAttempts,
                  reset: true
                });

                return AuthActions.login({
                  username: action.payload.user.email,
                  password: action.payload.user.password,
                  redirect: false,
                  attemptsName: attemptsName
                });
              }),
              catchError(error => of(new UserActions.ApiErrorAction({
                name: 'create',
                error
              }))));
        })));

    logout$: Observable<any> = createEffect(() => this._actions$
      .pipe(
        ofType(UserActions.ActionTypes.LOGOUT),
        withLatestFrom(this._store),
        mergeMap(([action, state]: [UserActions.LogoutAction, IAppState]) => {
          this.authService.clearAuthFromStorage();

          const response: (
            UserActions.ChangedAction |
            CompanyActions.ChangedAction |
            UserActions.ApiErrorAction
            )[] = [
            new UserActions.ChangedAction({
              current: null,
              errors: [],
            }),
            new CompanyActions.ChangedAction({
              current: null,
              errors: [],
            }),
          ];

          if (action.payload) {
            response.push(
              new UserActions.ApiErrorAction({
                name: 'logout',
                error: action.payload
              })
            );
          }

          return response;
        })));

    apiError$ = createEffect(() => this._actions$
      .pipe(
        ofType(UserActions.ActionTypes.API_ERROR),
        withLatestFrom(this._store),
        map(([action, state]: [UserActions.ApiErrorAction, IAppState]) => {
          return new UserActions.ChangedAction({
            errors: [
              action.payload,
              ...(state.user.errors || [])
            ],
          });
        })));

    connectCalendar$: Observable<any> = createEffect(() => this._actions$.pipe(
      ofType(UserActions.ActionTypes.CONNECT_CALENDAR),
      withLatestFrom(this._store),
      switchMap(([action, state]: [UserActions.ConnectCalendarAction, IAppState]) =>
        (action.payload === CalendarServiceModel.GOOGLE ?
            this.calendarService.connectToGoogle() :
            this.calendarService.connectToOutlook()
        ).pipe(
          map((res: CalendarConnectData) => {
            window.open(res.authorization_uri, '_self');
            return new UserActions.ConnectCalendarSuccessAction(action.payload);
          }),
          catchError(error => {
            this._store.dispatch(
              new ModalActions.OpenAction({
                cmpType: ToastComponent,
                props: {
                  view: 'error',
                  title: `Error when connecting Google calendar: ${error.statusText}`
                },
              }));

            return of(new UserActions.ConnectCalendarFailureAction(action.payload));
          })
        )
      )
    ));

    disconnectCalendar$: Observable<any> = createEffect(() => this._actions$.pipe(
      ofType(UserActions.ActionTypes.DISCONNECT_CALENDAR),
      withLatestFrom(this._store),
      switchMap(([action, state]: [UserActions.ConnectCalendarAction, IAppState]) =>
        (action.payload === CalendarServiceModel.GOOGLE ?
            this.calendarService.disconnectFromGoogle() :
            this.calendarService.disconnectFromOutlook()
        ).pipe(
          map(() => {
            this._store.dispatch(
              new ModalActions.OpenAction({
                cmpType: ToastComponent,
                props: {
                  view: 'success',
                  title: 'COMMON.SAVED'
                },
              }));

            return new UserActions.ChangedAction({
              current: Object.assign({}, state.user.current, {
                user_settings: Object.assign({}, state.user.current.user_settings, {
                  google: null,
                  microsoft: null,
                  calendarLoad: false
                })
              }),
              errors: [],
            });
          }),
          catchError(error => {
            this._store.dispatch(
              new ModalActions.OpenAction({
                cmpType: ToastComponent,
                props: {
                  view: 'error',
                  title: `Error when disconnecting Google calendar: ${error.statusText}`
                },
              }));

            return of(new UserActions.DisconnectCalendarFailureAction(action.payload));
          })
        ))
    ));

    /**
     * Commented out it because on impersonate page we don't need to get the user
     */
    // Any startWith observables - Should always BE LAST!
    // @Effect()
    // init$: Observable<Action> =
    //     this._actions$
    //         .ofType(UserActions.ActionTypes.INIT).pipe(
    //             startWith(new UserActions.InitAction()),
    //             map(() => {
    //                 return new UserActions.FetchAction();
    //             }));


    constructor(
        private _actions$: Actions,
        private _userService: UserService,
        private _store: Store<IAppState>,
        private calendarService: CalendarService,
        private authService: AuthService,
        private errorService: ErrorHandlingService
    ) { }
}
