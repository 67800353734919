import { IAppState } from '../../ngrx';
import { Store } from '@ngrx/store';
import { Cache, StorageService, StorageKeys } from '../storage/storage.service';
import { Injectable, Inject, forwardRef } from '@angular/core';
import { LocaleState } from '../../states/locale/locale.state';
import { PlatformLanguageToken } from '../../tokens';


@Injectable()
export class LocaleService extends Cache {
    private _inMemoryLocale: LocaleState.Locale;

    constructor(
        @Inject(forwardRef(() => PlatformLanguageToken)) private _languageToken: string,
        private _storageService: StorageService,
        private _store: Store<IAppState>,
    ) {
        super(_storageService);
        this.isObjectCache = true;
        this.key = StorageKeys.LOCALE;

        if (this._languageToken) {
            this._languageToken = this._languageToken.split('-')[0];
        }
        if (!Object.keys(LocaleState.Locale).includes(this._languageToken)) {
            this._languageToken = 'no';
        }
    }

    public get locale(): LocaleState.Locale {
        this._inMemoryLocale = this.cache ? this.cache.locale : this._languageToken;
        return this._inMemoryLocale;
    }

    public set locale(locale: LocaleState.Locale) {
        // store in persistence
        this.cache = { locale };
        // always update in-memory locale for rapid access elsewhere
        this._inMemoryLocale = locale;
    }

    public get inMemoryLocale(): LocaleState.Locale {
        return this._inMemoryLocale;
    }
}
