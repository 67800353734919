// libs
import {
    Action,
} from '@ngrx/store';
// app
import { type } from '../helpers/type';
// various app module state
import { UIState } from '../states/ui/ui.state';
import { uiReducer } from '../reducers/ui.reducer';

import { companyReducer } from '../modules/company/reducers/company.reducer';
import { CompanyState } from '../modules/company/states/company.state';
import { UserState } from '../modules/user/states/user.state';
import { userReducer } from '../modules/user/reducers/user.reducer';
import { NotificationState } from '../modules/notification/states/notification.state';
import { notificationReducer } from '../modules/notification/reducers/notification.reducer';
import { AuthState, loginReducer } from '../../auth';

export namespace AppActions {
    const CATEGORY: string = 'App';

    export interface IActions {
        NOOP: string;
    }

    export const ActionTypes: IActions = {
        NOOP: type(`${CATEGORY} Noop`),
    };

    export class NoopAction implements Action {
        type = ActionTypes.NOOP;
        payload: string = null;
    }

    export type Actions = NoopAction;
}

// overall shape of app state
export interface IAppState {
    company: CompanyState.IState;
    user: UserState.IState;
    ui: UIState.IState;
    notification: NotificationState.IState;
    auth: AuthState;
}

export const AppReducer = {
    company: companyReducer,
    user: userReducer,
    ui: uiReducer,
    notification: notificationReducer,
    auth: loginReducer
};
