import { Injectable } from '@angular/core';
import {TDevice} from "../../states/device/device.state";

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  widthBreakpoints = {
    mobileMin: 320,
    mobileMax: 460,
    tabletMin: 461,
    tabletMax: 960,
    desktopMin: 961,
    desktopMax: 1920
  }

  constructor() { }

  public getDeviceTypeByScreenRes(screenW: number, screenH: number): TDevice {
    const {
      mobileMin, mobileMax,
      tabletMin, tabletMax,
      desktopMin, desktopMax
    } = this.widthBreakpoints

    if (mobileMin <= screenW && screenW <= mobileMax) {
      return 'mobile';
    }

    if (tabletMin <= screenW && screenW <= tabletMax) {
      return 'tablet'
    }

    if (desktopMin <= screenW) {
      return 'desktop'
    }
  }
}
