import { Component, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { isPlatformServer } from '@angular/common';
// libs
import { Store } from '@ngrx/store';
import { IAppState } from '../core/ngrx';
import { UserActions } from '../core/modules/user/actions/user.action';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
    public isServer: boolean;

    constructor(
        @Inject(PLATFORM_ID) private _platformId: Object,
        private _store: Store<IAppState>,
    ) {
        this.isServer = isPlatformServer(this._platformId);
    }

    public ngOnInit(): void {
        this._store.dispatch(new UserActions.FetchAction());
    }
}
