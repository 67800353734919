import { HttpErrorResponse } from '@angular/common/http';

import { User } from '../../../../shared/models/user/user.model';

export namespace UserState {
    export interface IErrorState {
        name: string;
        error: HttpErrorResponse;
    }

    export interface IState {
        /**
         * Current Authenticated User
         */
        current?: User;
        /**
         * User api errors that have occurred during user session.
         */
        errors?: Array<IErrorState>;
    }

    export const initialState: IState = {
        current: null,
        errors: [],
    };
}
