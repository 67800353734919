import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../ui/button/button.component';
import { CookiesService } from '../../services/cookies.service';
import { ModalActions } from '../../../core/actions/modal/modal.action';
import { Store } from '@ngrx/store';
import { CookiesManagingComponent, CookiesManagingModalData } from '../cookies-managing/cookies-managing.component';
import { ModalComponent } from '../../../ui/modal/modal.component';
import { DATA_INJECTION_TOKEN } from '../../../core/services/modal/modal.service';
import { LanguageChoiserComponent } from '../language-choiser/language-choiser.component';



export interface CookiesConsentModalData {
  modalName: string;
}

@Component({
  selector: 'app-consent',
  standalone: true,
  imports: [
    TranslateModule,
    ButtonComponent,
    ModalComponent,
    LanguageChoiserComponent
  ],
  templateUrl: './cookies-consent.component.html',
  styleUrl: './cookies-consent.component.scss'
})
export class CookiesConsentComponent {
  cookiesService = inject(CookiesService);
  store = inject(Store);
  data = inject<CookiesConsentModalData>(DATA_INJECTION_TOKEN);

  manageCookies(): void {
    const modalName = 'manage-cookies-modal';

    this.store.dispatch(
      new ModalActions.OpenAction<CookiesManagingModalData>({
        cmpType: CookiesManagingComponent,
        fixed: true,
        props: {
          modalName
        }
      }));
  }

  acceptCookies(): void {
    this.cookiesService.setConsent({
      analytics_storage: 'granted',
      ad_personalization: 'granted',
      ad_user_data: 'granted',
      ad_storage: 'granted'
    });

    this.store.dispatch(new ModalActions.CloseAction({
      modalName: this.data.modalName
    }));
  }
}
