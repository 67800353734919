import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { NgModule, ErrorHandler, PLATFORM_ID, NO_ERRORS_SCHEMA, APP_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import * as Sentry from '@sentry/angular';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { CustomErrorHandler } from './shared/services/error-handler/error-handler.service';
import { BaseComponent } from './shared/components/base/base.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { AppState } from './app.service';
import { LayoutComponent } from './layout/layout.component';
import { LoaderPageComponent } from './loader-page/loader-page.component';
import { SharedModule } from './shared/shared.module';
import { PlatformLanguageToken, TranslateLoaderPathToken } from './core/tokens';
import { CoreModule } from './core/core.module';
import { AuthSimpleModule } from './auth-simple/auth-simple.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorTranslations } from './shared/services/paginator-translations/paginator-translations';
import { LocaleService } from './core/services/locale/locale.service';
import { StorageService } from './core/services/storage/storage.service';
import { WebHelperService } from './core/services/web-helper/web-helper.service';
import { CoreHttpInterceptor } from './core/services/core-request/core.interceptor.service';
import { NavMenuService } from './core/services/nav-menu/nav-menu.service';
import { ModalService } from './core/services/modal/modal.service';
import { FilesService } from './core/services/files/files.service';
import { DatesService } from './core/services/dates/dates.service';
import { VideoService } from './core/services/video/video.service';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from './company-profile/services/custom-reuse-strategy';
import { CookiesConsentComponent } from './shared/components/cookies-consent/cookies-consent.component';
import { Angulartics2GoogleAnalytics, Angulartics2Module } from 'angulartics2';

Sentry.init({
  dsn: environment.sentryAPIKey,
});

const APP_PROVIDERS = [
  AppState,
];

export function platformLangFactory(): 'no-No' {
  return 'no-No'; // Set browser language as default
}

export function initEventHandler(platformId: object): ErrorHandler {
  const isServer = isPlatformServer(platformId);

  // Don't use sentry on development
  if (!environment.production) {
    return new ErrorHandler();
  }

  // Only for browser
  if (isServer) {
    return new ErrorHandler();
  }

  return new CustomErrorHandler();
}

@NgModule({ declarations: [
        AppComponent,
        LayoutComponent,
        LoaderPageComponent
    ],
    schemas: [
        NO_ERRORS_SCHEMA,
    ],
    bootstrap: [AppComponent], imports: [BrowserAnimationsModule,
        BrowserModule,
        BaseComponent,
        CoreModule.forRoot([
            { provide: APP_ID, useValue: 'serverApp' },
            {
                provide: PlatformLanguageToken,
                useFactory: platformLangFactory
            },
            {
                provide: TranslateLoaderPathToken,
                useValue: '/assets/i18n/'
            }
        ]),
        SharedModule,
        AuthSimpleModule,
        AppRoutingModule,
        StoreDevtoolsModule.instrument({ maxAge: 50 }),
        Angulartics2Module.forRoot(),
        CookiesConsentComponent],
  providers: [
        LocaleService,
        StorageService,
        WebHelperService,
        ModalService,
        NavMenuService,
        FilesService,
        DatesService,
        VideoService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CoreHttpInterceptor,
            multi: true,
        },
        APP_PROVIDERS,
        {
            provide: ErrorHandler,
            useFactory: initEventHandler,
            deps: [PLATFORM_ID],
        },
        { provide: 'googleTagManagerId', useValue: environment.googleTagManagerId },
        {
            provide: MatPaginatorIntl,
            useClass: PaginatorTranslations,
        },
        { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
        provideHttpClient(withInterceptorsFromDi(), withFetch()),
        provideClientHydration()
    ] })
export class AppModule {
  constructor(angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics) {
    angulartics2GoogleAnalytics.startTracking();
  }
}
