import { Action } from '@ngrx/store';
import { type } from '../../helpers/type';

export namespace NavMenuActions {
    const CATEGORY: string = 'Nav Menu';

    export interface IActions {
        MOBILE_TOGGLE: string;
    }

    export const ActionTypes: IActions = {
        MOBILE_TOGGLE: type(`${CATEGORY} Mobile Toggle`),
    };

    export class MobileToggleAction implements Action {
        type = ActionTypes.MOBILE_TOGGLE;

        constructor(public payload?: boolean) { }
    }

    export type Actions = MobileToggleAction;
}
