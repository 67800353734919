import { UserState } from '../states/user.state';
import { UserActions } from '../actions/user.action';



export function userReducer(
    state: UserState.IState = UserState.initialState,
    action: UserActions.Actions,
): UserState.IState {
    const changeState = (customPayload?: any) => {
        return Object.assign({}, state, customPayload || action.payload);
    };

    switch (action.type) {
        case UserActions.ActionTypes.CHANGED:
            return changeState();
        case UserActions.ActionTypes.CHANGED_SETTINGS:
            return Object.assign({}, state, {
                current: Object.assign({}, state.current, {
                    user_settings: action.payload
                }
            ) });

        case UserActions.ActionTypes.CONNECT_CALENDAR:
            return Object.assign({}, state, {
              current: Object.assign({}, state.current, {
                  user_settings: Object.assign({}, state.current.user_settings, {
                      calendarLoad: true
                  })
                }
              ) });

        case UserActions.ActionTypes.CONNECT_CALENDAR_SUCCESS:
            return Object.assign({}, state, {
                current: Object.assign({}, state.current, {
                    user_settings: Object.assign({}, state.current.user_settings, {
                        calendarLoad: false
                    })
                  }
                ) });

        case UserActions.ActionTypes.CONNECT_CALENDAR_FAILURE:
            return Object.assign({}, state, {
                current: Object.assign({}, state.current, {
                    user_settings: Object.assign({}, state.current.user_settings, {
                        calendarLoad: false
                    })
                  }
                ) });

        case UserActions.ActionTypes.DISCONNECT_CALENDAR:
            return Object.assign({}, state, {
                current: Object.assign({}, state.current, {
                    user_settings: Object.assign({}, state.current.user_settings, {
                        calendarLoad: true
                    })
                  }
                ) });

        case UserActions.ActionTypes.DISCONNECT_CALENDAR_SUCCESS:
            return Object.assign({}, state, {
                current: Object.assign({}, state.current, {
                    user_settings: Object.assign({}, state.current.user_settings, {
                        calendarLoad: false
                    })
                  }
                ) });

        case UserActions.ActionTypes.DISCONNECT_CALENDAR_FAILURE:
            return Object.assign({}, state, {
                current: Object.assign({}, state.current, {
                    user_settings: Object.assign({}, state.current.user_settings, {
                        calendarLoad: false
                    })
                  }
                ) });

        default:
            return state;
    }
};
