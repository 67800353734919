<div style="height:100vh;
                  width:100%;
                  background: rgb(45, 37, 51);">
    <div style="position: absolute;
                        width: 100%;
                        height: auto;
                        top: 50%;
                        margin-top: -90px;
                        text-align: center;
                        color: rgb(45, 37, 51);
                        -webkit-animation: fadein .5s; /* Safari, Chrome and Opera > 12.1 */
                        -moz-animation: fadein .5s; /* Firefox < 16 */
                        -ms-animation: fadein .5s; /* Internet Explorer */
                        -o-animation: fadein .5s; /* Opera < 12.1 */
                        animation: fadein .5s;">
        <img style="height: 180px;"
             src="assets/images/stars.png" />
    </div>
</div>
