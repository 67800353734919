<app-modal [closeButton]="false" class="modal">
  <ng-container modal-content>
    <div class="heading-row">
      <h2 class="h2">{{ 'COOKIES.TITLE' | translate }}</h2>

      <app-language-choiser class="language-picker" mode="short"></app-language-choiser>
    </div>

    <p>{{ 'COOKIES.MESSAGE' | translate }}</p>
  </ng-container>

  <div modal-footer class="actions">
    <app-button
      class="action-button action-button-manage"
      [config]="{
        view: 'link',
        label: 'COOKIES.MANAGE'
      }"
      (click)="manageCookies()"
    />

    <app-button
      class="action-button action-button-accept"
      [config]="{
        view: 'primary',
        label: 'COOKIES.ACCEPT'
      }"
      (click)="acceptCookies()"
    />
  </div>
</app-modal>

