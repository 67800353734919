import { ApplicationRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { distinctUntilChanged, filter, first, takeUntil } from 'rxjs/operators';
import { MetaModel } from './shared/models/meta/meta.model';
import { MetaService } from './shared/services/meta/meta.service';
import { BaseComponent } from './shared/components/base/base.component';
import { select, Store } from '@ngrx/store';
import { IAppState } from './core/ngrx';
import { User } from './shared/models/user/user.model';
import { WSService } from './shared/services/ws/ws.service';
import { NotificationService } from './core/modules/notification/services/notification.service';
import { NotificationChannel, NotificationSender } from './shared/models/notification/notification.model';
import { DeviceActions } from './core/actions/device/device.action';
import { CookiesService } from './shared/services/cookies.service';
import { transitionAnimation } from '../scss/themes/animation';
import { UserService } from './core/modules/user/services/user.service';
import { ModalActions } from './core/actions/modal/modal.action';
import {
  CookiesConsentComponent,
  CookiesConsentModalData
} from './shared/components/cookies-consent/cookies-consent.component';



/*
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './app.component.scss',
  ],
  templateUrl: './app.component.html',
  animations: [transitionAnimation],
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  protected isBrowser: boolean;
  cookiesConsentModalName = 'cookies-consent-modal';

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private _platformId: Object,
    private readonly applicationRef: ApplicationRef,
    private _router: Router,
    private _metaService: MetaService,
    private _store: Store<IAppState>,
    private _wsService: WSService,
    private _notificationService: NotificationService,
    private cookiesService: CookiesService,
    private userService: UserService
  ) {
    super();
    this.isBrowser = isPlatformBrowser(this._platformId);
  }

  ngOnInit(): void {
    this._initDeviceData();

    if (this.isBrowser) {
      this.checkCookiesConsent();

      this._router.events.pipe(
        takeUntil(this.destroy$))
        .subscribe(() => {
          window.scrollTo(0, 0);
        });
    }

    // Set root meta
    const meta: MetaModel = {
      title: 'Nuu',
      description: 'Rekruttering har aldri vært enklere',
      tags: [{
        name: 'og:image',
        value: '/assets/images/nuu-social-preview.png'
      }]
    };
    this._metaService.updateSeoTags(meta);

    this.applicationRef.isStable.pipe(
      first((isStable) => isStable)
    ).subscribe(() => {
      // reconnect to notification WS every time user is changed or user changed company or user changed active_role
      this._store.select(s => s.user.current).pipe(
        filter(user => !!user),
        distinctUntilChanged((prev, curr) => {
          return prev?.id === curr?.id &&
            prev?.active_role === curr?.active_role &&
            prev?.user_profile?.company?.id === curr?.user_profile?.company?.id;
        }),
        takeUntil(this.destroy$)
      ).subscribe(user => {
        this.connectToNotifications(user);
      });
    });
  }

  ngOnDestroy(): void {
    if (this._notificationService.connections[NotificationChannel.GENERAL]) {
      this._wsService.close(this._notificationService.connections[NotificationChannel.GENERAL]);
    }

  }

  checkCookiesConsent(): void {
    if (!this.cookiesService.getConsent()) {
      this.showCookiesConsentModal();
    }
  }

  /**
   * establish connection with WS notifications
   * close previous connection if exists
   */
  connectToNotifications(user: User): void {
    // if user is not logged in - close connection
    if (!user && this._notificationService.connections[NotificationChannel.GENERAL]) {
      this._wsService.close(this._notificationService.connections[NotificationChannel.GENERAL]);
      return;
    }

    if (!user) {
      return;
    }

    const wsRoom = user.id;
    const isEmployee = this.userService.isUserEmployee(user);

    this._notificationService.connectToNotifications(
      wsRoom,
      isEmployee ? user.user_profile.company?.id : null,
      isEmployee ? NotificationSender.EMPLOYEE : NotificationSender.APPLICANT,
      NotificationChannel.GENERAL
    );
  }

  private _initDeviceData() {
    if (this.isBrowser) {
      this._store.dispatch(new DeviceActions.NavigatorDeviceAction(window.navigator.userAgent));
      this._store.dispatch(new DeviceActions.DeviceResolutionAction([window.innerWidth, window.innerHeight]));
      // register device res callback
      window.addEventListener('resize', () => {
        this._store.dispatch(new DeviceActions.DeviceResolutionAction([window.innerWidth, window.innerHeight]));
      });
    }
  }

  showCookiesConsentModal(): void {
    this._store.dispatch(
      new ModalActions.OpenAction<CookiesConsentModalData>({
        cmpType: CookiesConsentComponent,
        fixed: true,
        props: {
          modalName: this.cookiesConsentModalName
        },
        modalOptions: {
          disableClose: true
        }
      }));

    this._store.pipe(
      takeUntil(this.destroy$),
      select(s => s.ui.modal.latestResult),
      filter(res => !!res && res.modalName === 'manage-cookies-modal'),
    ).subscribe({
      next: () => {
        this.closeCookiesConsentModal();
      },
    });
  }

  closeCookiesConsentModal(): void {
    this._store.dispatch(new ModalActions.CloseAction({
      modalName: this.cookiesConsentModalName
    }));
  }
}
