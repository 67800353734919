import { ErrorHandler } from "@angular/core";
import * as Sentry from '@sentry/browser';

export class CustomErrorHandler implements ErrorHandler {
    constructor() { }

    handleError(error: any): void {
        Sentry.captureException(error.originalError || error);

        throw error;
    }
}

export default CustomErrorHandler;
