import { MatDialogConfig } from "@angular/material/dialog";

export namespace NavMenuState {

    export interface IState {
        /**
         * Whether mobile menu is open or not
         */
        isMobileNavOpen: boolean;
    }

    export const initialState: IState = {
        isMobileNavOpen: false,
    };
}
