import { HttpErrorResponse } from '@angular/common/http';
import {
  GeneralNotifications
} from '../../../../shared/models/notification/notification.model';
import { WsErrorData} from '../../../../shared/models/message/message.model';
export namespace NotificationState {
    export interface IErrorState {
        name: string;
        error: HttpErrorResponse
    }

    export interface IState {
        /**
         * Notification Stats
         */
        total?: GeneralNotifications;
        /**
         * Notification api errors that have occurred during notification session.
         */
        errors?: string[];
    }

    export const initialState: IState = {
        total: null,
        errors: [],
    };
}
