import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {debounceTime, map, mergeMap, startWith} from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ModalActions } from '../actions/modal/modal.action';
import { NavMenuActions } from '../actions/nav-menu/nav-menu.action';
import { LocaleActions } from '../actions/locale/locale.action';
import { UIActions } from '../actions/ui/ui.action';
import { UserActions } from '../modules/user/actions/user.action';
import { LocaleService } from '../services/locale/locale.service';
import { ModalService } from '../services/modal/modal.service';
import { NavMenuService } from '../services/nav-menu/nav-menu.service';
import { CompanyPageEditActions } from '../actions/company-page-edit/company-page-edit.action';
import { JobActions } from '../../job/actions/job.action';
import {DeviceActions} from "../actions/device/device.action";
import {DeviceState, TDevice} from "../states/device/device.state";
import {UIState} from "../states/ui/ui.state";
import {DeviceService} from "../services/device/device.service";

const defaultLanguage = environment.defaultLanguage;

@Injectable()
export class UIEffects {

    localeSet$: Observable<Action> = createEffect(() => this.actions$
      .pipe(
        ofType(LocaleActions.ActionTypes.SET),
        mergeMap((action: LocaleActions.SetAction) => {
          this.localeService.locale = action.payload;
          this.translateService.use(action.payload.toString());
          return [
            new UIActions.ChangedAction({ locale: action.payload }),
            new UserActions.ChangeLanguageAction(action.payload.toString()),
          ];
        })));


    modalOpen$: Observable<Action> = createEffect(() => this.actions$
      .pipe(
        ofType(ModalActions.ActionTypes.OPEN),
        map((action: ModalActions.OpenAction) => {
          const details = this.modal.open(action.payload);
          return new ModalActions.OpenedAction({
            open: true,
            cmpType: details.cmpType,
            title: details.trackTitle,
            latestResult: null // reset when opening
          });
        })));

    modalOpened$: Observable<Action> = createEffect(() => this.actions$.pipe(
      ofType(ModalActions.ActionTypes.OPENED),
      map((action: ModalActions.OpenedAction) =>
        new UIActions.ChangedAction({
          modal: action.payload,
        }),
      )));

    modalClose$: Observable<Action> = createEffect(() => this.actions$
      .pipe(
        ofType(ModalActions.ActionTypes.CLOSE),
        map((action: ModalActions.CloseAction) => {
          const closeResult = this.modal.close(action.payload);
          return new ModalActions.ClosedAction({
            open: false,
            cmpType: null,
            title: null,
            // keep null values to be consistent (instead of undefined)
            latestResult: typeof closeResult === 'undefined' ? null : closeResult,
          });
        })));

    modalClosed$: Observable<Action> = createEffect(() => this.actions$.pipe(
      ofType(ModalActions.ActionTypes.CLOSED),
      map((action: ModalActions.ClosedAction) =>
        new UIActions.ChangedAction({
          modal: action.payload,
        }),
      )));

    mobileMenuToggle$: Observable<Action> = createEffect(() => this.actions$
      .pipe(
        ofType(NavMenuActions.ActionTypes.MOBILE_TOGGLE),
        map((action: NavMenuActions.MobileToggleAction) => {
          const isMobileNavOpen = this.navMenuService.mobileToggle(action.payload);
          return new UIActions.ChangedAction({
            navMenu: { isMobileNavOpen }
          });
        })));

    editMode$: Observable<Action> = createEffect(() => this.actions$
      .pipe(
        ofType(CompanyPageEditActions.ActionTypes.SWITCH_EDIT_MODE),
        map((action: CompanyPageEditActions.SwitchEditModeAction) => {
          return new UIActions.ChangedAction({
            isCompanyEditMode: {
              isEditMode: action.payload.isEditMode,
              landingTypeToEdit: action.payload.landingTypeToEdit
            }
          });
        })));

    jobEditMode$: Observable<Action> = createEffect(() => this.actions$
      .pipe(
        ofType(JobActions.ActionTypes.SWITCH_EDIT_MODE),
        map((action: JobActions.SwitchEditModeAction) => {
          return new UIActions.ChangedAction({
            isJobEditMode: action.payload
          });
        })));

    // Any startWith observables - Should always BE LAST!
    localeInit$: Observable<Action> = createEffect(() => this.actions$
      .pipe(
        ofType(LocaleActions.ActionTypes.INIT),
        startWith(new LocaleActions.InitAction()),
        map((action: LocaleActions.InitAction) => {
          this.translateService.setDefaultLang(defaultLanguage);
          return new LocaleActions.SetAction(this.localeService.locale);
        })));

    navigatorDeviceChange$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(DeviceActions.ActionTypes.NAVIGATOR_DEVICE),
        map((action: DeviceActions.NavigatorDeviceAction) => {
            const devicePayload: DeviceState.IState = {
              userAgent: action.payload
            };
            const UIpayload: UIState.IState = {
                device: devicePayload
            };

            return new UIActions.ChangedAction(UIpayload);
        })));

    deviceResolutionChange$: Observable<Action> = createEffect(() => this.actions$.pipe(
      ofType(DeviceActions.ActionTypes.DEVICE_RES),
      debounceTime(300),
      map((action: DeviceActions.DeviceResolutionAction) => {
          const devicePayload: DeviceState.IState = {
            calculatedDeviceType: null,
            screenW: 0,
            screenH: 0
          };

          if (Array.isArray(action.payload)) {
            const [screenWidth, screenHeight] = action.payload as [number, number];
            const deviceType = this.deviceService.getDeviceTypeByScreenRes(screenWidth, screenHeight);
            devicePayload.calculatedDeviceType = deviceType;
            devicePayload.screenW = screenWidth;
            devicePayload.screenH = screenHeight;
          }

          const UIpayload: UIState.IState = {
              device: devicePayload
          };

         return new UIActions.ChangedAction(UIpayload);
      }
      )));

    constructor(
        private actions$: Actions,
        private localeService: LocaleService,
        private translateService: TranslateService,
        private modal: ModalService,
        private navMenuService: NavMenuService,
        private deviceService: DeviceService
    ) { }

}
