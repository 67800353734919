import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {EMPTY, Observable} from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';

import { GeneralNotifications } from '../../../../shared/models/notification/notification.model';
import { WSStatus } from '../../../../shared/models/message/message.model';

import { IAppState } from '../../../ngrx/index';
import { NotificationActions } from '../actions/notification.action';
import { NotificationService } from '../services/notification.service';


@Injectable()
export class NotificationEffects {
    setGeneralNotifications$: Observable<Action> = createEffect(() => this.actions$
      .pipe(
        ofType(NotificationActions.ActionTypes.SET_GENERAL),
        withLatestFrom(this.store),
        switchMap(([action, state]: [NotificationActions.SetGeneralAction, IAppState]) => {
          const isSuccessful = action.payload.status !== WSStatus.ERROR;

          if (isSuccessful) {
            const notifications = action.payload.data as GeneralNotifications;

            return [
              new NotificationActions.ChangedAction({
                total: notifications,
                errors: [],
              })
            ];
          } else {
            return EMPTY;
          }
        })));

    readNotification$: Observable<Action> = createEffect(() => this.actions$
      .pipe(
        ofType(NotificationActions.ActionTypes.READ_NOTIFICATION),
        switchMap((action: NotificationActions.ReadNotificationAction) => {
          this.notificationService.readNotifications(
            action.payload.message,
            action.payload.sender,
            action.payload.channel
          );

          return [new NotificationActions.ReadNotificationSuccessAction()];
        })));


    constructor(
      private actions$: Actions,
      private notificationService: NotificationService,
      private store: Store<IAppState>
    ) { }
}
