import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UIEffects } from './effects/ui.effect';
import { throwIfAlreadyLoaded } from './helpers/module-import-guard';
import { NewApplicationService } from './modules/application/services/application.service';
import { JobService } from './modules/job/services/job.service';
import { ChatService } from './modules/chat/chat.service';
import { CompanyService } from './modules/company/services/company.service';
import { CompanyEffects } from './modules/company/effects/company.effect';
import { FinnService } from './modules/finn/services/finn.service';
import { NotificationService } from './modules/notification/services/notification.service';
import { NotificationEffects } from './modules/notification/effects/notification.effect';
import { DeviceDetectorService } from 'ngx-device-detector';
// modules
import { UserService } from './modules/user/services/user.service';
import { UserEffects } from './modules/user/effects/user.effect';
import { EmployeeService } from './modules/employee/services/employee.service';
import { AppReducer } from './ngrx';
// app
import {
  FilesService
} from './services/files/files.service';
import {
  LocaleService
} from './services/locale/locale.service';
import {
  ModalService
} from './services/modal/modal.service';
import {
  WebHelperService
} from './services/web-helper/web-helper.service';
import {
  VideoService,
} from './services/video/video.service';
import {
  StorageService,
} from './services/storage/storage.service';
import {
  NavMenuService
} from './services/nav-menu/nav-menu.service';
import { TranslateLoaderPathToken } from './tokens';
import { environment } from '../../environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import { DatesService } from './services/dates/dates.service';


// factories
export function createTranslateLoader(http: HttpClient,
    translateLoaderPathToken: string) {
    return new TranslateHttpLoader(http, translateLoaderPathToken, `.json?cb=${environment.langCacheHash}`);
}

// For AoT compilation:
export function win() {
    return window;
}

export function tokenGetter(): string {
    return window.localStorage.getItem('id_token');
}

export const BASE_PROVIDERS: any[] = [
    LocaleService,
    StorageService,
    WebHelperService,
    ModalService,
    NavMenuService,
    FilesService,
    DatesService,
    VideoService,
    UserService,
    CompanyService,
    ClipboardService,
    ChatService,
    NotificationService,
    FinnService,
    NewApplicationService,
    JobService,
    EmployeeService
];

@NgModule({
    imports: [
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient, TranslateLoaderPathToken]
            }
        }),
        StoreModule.forRoot(AppReducer, {
          runtimeChecks: {
            strictStateImmutability: false,
            strictActionImmutability: false,
          },
        }),
        EffectsModule.forRoot([
            CompanyEffects,
            UserEffects,
            UIEffects,
            NotificationEffects
        ]),

    ],
    providers: [
        ...BASE_PROVIDERS,
      {
        provide: DeviceDetectorService,
      }
    ],
})

export class CoreModule {
    static forRoot(configuredProviders: Array<any>): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                ...BASE_PROVIDERS,
                ...configuredProviders
            ],
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}

