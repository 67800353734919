import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { IAppState } from '../../ngrx';
import { Store } from '@ngrx/store';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class NavMenuService {
    public isMobileOpened = false;
    private _renderer: Renderer2;
    constructor(
        private _store: Store<IAppState>,
        @Inject(DOCUMENT) private _document: Document,
        private _rendererFactory: RendererFactory2
    ) {
        this._renderer = _rendererFactory.createRenderer(null, null);
    }

    public mobileToggle(open?: boolean): boolean {
        if (open === true) {
            // Force open nav
            this.isMobileOpened = true;
        } else if (open === false) {
            // Force close nav
            this.isMobileOpened = false;
        } else {
            this.isMobileOpened = !this.isMobileOpened;
        }

        // Toggle class for body to prevent page scroll
        if (this.isMobileOpened) {
            this._renderer.addClass(this._document.body, 'mobile-nav-open');
        } else {
            this._renderer.removeClass(this._document.body, 'mobile-nav-open');
        }

        return this.isMobileOpened;
    }
}
