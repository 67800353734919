import { Company } from '../../../../shared/models/company/company.model';
export namespace CompanyState {
    export interface IState {
        /**
         * Current Authenticated Company
         */
        current?: Company;
        /**
         * Company api errors that have occurred during user session.
         */
        errors?: Array<any>;
    }

    export const initialState: IState = {
        current: null,
        errors: [],
    };
}
