
export type TDevice = 'mobile' | 'tablet' | 'desktop';

export namespace DeviceState {

    export interface IState {
        /**
         * Whether mobile menu is open or not
         */
        calculatedDeviceType?: TDevice | null;
        userAgent?: string;
        screenW?: number;
        screenH?: number;
    }

    export const initialState: IState = {};
}
