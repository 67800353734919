import { LocaleState } from '../locale/locale.state';
import { ModalState } from '../modal/modal.state';
import { NavMenuState } from '../nav-menu/nav-menu.state';
import { DeviceState } from '../device/device.state';

export namespace UIState {
    export interface IState {
        locale?: LocaleState.Locale;
        modal?: ModalState.IState;
        navMenu?: NavMenuState.IState;
        device?: DeviceState.IState;
        isJobEditMode?: boolean;
        isCompanyEditMode?: {
          isEditMode?: boolean;
          landingTypeToEdit?: string;
        };
    }

    export const initialState: IState = {
        locale: null,
        modal: ModalState.initialState,
        navMenu: NavMenuState.initialState,
        device: DeviceState.initialState,
        isJobEditMode: false,
        isCompanyEditMode: {
          isEditMode: false,
          landingTypeToEdit: ''
        }
    };
}
