import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Company } from '../../../../shared/models/company/company.model';
import { IAppState } from '../../../ngrx/index';
import { CompanyActions } from '../actions/company.action';
import { CompanyService } from '../services/company.service';
import { UserActions } from '../../user/actions/user.action';



@Injectable()
export class CompanyEffects {
    fetchCurrentCompany$: Observable<Action> = createEffect(() => this._actions$
      .pipe(
        ofType(CompanyActions.ActionTypes.FETCH),
        switchMap((action: CompanyActions.FetchAction) => {
          const { companyName, customDomain } = action.payload || {};
          let company$: Observable<Company>;
          if (companyName) {
            company$ = this._companyService
              .getCompanyByName(companyName);
          } else if (customDomain) {
            company$ = this._companyService
              .getCompanyByCustomDomain(customDomain);
          } else {
            company$ = this._companyService
              .getCurrentCompany();
          }

          return company$.pipe(
            map(company =>
              {
                return new CompanyActions.ChangedAction({
                  current: company,
                });
              },
            ),
            catchError(err => of(new CompanyActions.ApiErrorAction(err))),
          );
        })));

    patch$ = createEffect(() => this._actions$
      .pipe(
        ofType(CompanyActions.ActionTypes.PATCH),
        withLatestFrom(this._store),
        filter(([action, state]: [CompanyActions.PatchAction, IAppState]) => !!state.company.current),
        switchMap(([action, state]: [CompanyActions.PatchAction, IAppState]) => {
          const req = Object.assign({
            id: state.company.current.id,
          }, action.payload);
          return this._companyService
            .patchCompany(req).pipe(
              map(_company => {
                return new CompanyActions.PatchSuccessAction(_company);
              }),
              catchError(error => of(new CompanyActions.ApiErrorAction({
                name: 'patch',
                error
              }))));
        })));

    patchSuccess$: Observable<Action> = createEffect(() => this._actions$
      .pipe(
        ofType(CompanyActions.ActionTypes.PATCH_SUCCESS),
        map((action: CompanyActions.PatchSuccessAction) => {
          const company = action.payload;
          return new CompanyActions.ChangedAction({
            current: company,
          });
        })));

    updateLanding$ = createEffect(() => this._actions$
      .pipe(
        ofType(CompanyActions.ActionTypes.UPDATE_LANDING),
        withLatestFrom(this._store),
        filter(([action, state]: [CompanyActions.UpdateLandingAction, IAppState]) => !!state.company.current),
        mergeMap(([action, state]: [CompanyActions.UpdateLandingAction, IAppState]) => {
          const user = Object.assign({}, state.user.current, {
            user_profile: Object.assign({}, state.user.current.user_profile, {
              company: Object.assign({}, state.user.current.user_profile.company, {
                logo: action.payload.landing?.logo,
                logo_bgc: action.payload.logo_bgc,
              })
            })
          });

          return [
            new UserActions.ChangedAction({
              current: user,
            }),
            new CompanyActions.ChangedAction({
            current: Object.assign({}, state.company, action.payload)
          })
          ];
        })
      ));

    apiError$ = createEffect(() => this._actions$
      .pipe(
        ofType(CompanyActions.ActionTypes.API_ERROR),
        withLatestFrom(this._store),
        map(([action, state]: [CompanyActions.ApiErrorAction, IAppState]) => {
          return new CompanyActions.ChangedAction({
            errors: [
              action.payload,
              ...(state.user.errors || [])
            ],
          });
        })));


    constructor(
        private _actions$: Actions,
        private _companyService: CompanyService,
        private _store: Store<IAppState>
    ) { }
}
