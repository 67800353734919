import { UIState } from '../states/ui/ui.state';
import { UIActions } from '../actions/ui/ui.action';

export function uiReducer(
    state: UIState.IState = UIState.initialState,
    action: UIActions.Actions
): UIState.IState {
    const changeState = () => {
        return Object.assign({}, state, action.payload);
    };
    switch (action.type) {
        case UIActions.ActionTypes.CHANGED:
            return changeState();
        default:
            return state;
    }
}
