import { ActionReducer } from '@ngrx/store';
import { NotificationState } from '../states/notification.state';
import { NotificationActions } from '../actions/notification.action';

export function notificationReducer(
    state: NotificationState.IState = NotificationState.initialState,
    action: NotificationActions.Actions,
): NotificationState.IState {
    const changeState = (customPayload?: any) => {
        return Object.assign({}, state, customPayload || action.payload);
    };
    switch (action.type) {
        case NotificationActions.ActionTypes.CHANGED:
            return changeState();
        default:
            return state;
    }
};
